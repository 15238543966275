class Menutrigger extends HTMLElement {
  constructor() {
    super();
    this.addEventListener('click', () => this.toggle());
    this.expanded = this.getAttribute('expanded') || 'false';
  }

  toggle() {
    this.expanded = this.expanded === 'true' ? 'false' : 'true';
    this.classList.toggle('pushed', this.expanded === 'true');
    // Find button and set aria-expanded attribute and change aria-label
    const button = this.querySelector('button');
    if (button) {
      button.setAttribute('aria-expanded', this.expanded);
      const text =
        this.expanded === 'true' ? 'Lukk hovedmeny' : 'Åpne hovedmeny';
      button.setAttribute('aria-label', text);
    }

    const event = new CustomEvent('menutrigger:expand', {
      detail: this.expanded,
    });
    document.dispatchEvent(event);
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-menutrigger', Menutrigger);
}
