class AmediaMenuContainer extends HTMLElement {
  toggle(evt) {
    this.content.setAttribute(
      'aria-hidden',
      evt.detail === 'true' ? 'false' : 'true'
    );
  }

  connectedCallback() {
    if (this.created) {
      return;
    }
    this.content = this.getElementsByClassName('amedia-menu').amediamenu;
    document.addEventListener('menutrigger:expand', (evt) => this.toggle(evt));
    this.created = true;
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-menu-container', AmediaMenuContainer);
}
