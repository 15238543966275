class AmediaMenubar extends HTMLElement {
  connectedCallback() {
    if (this.created) {
      return;
    }
    document.addEventListener('menutrigger:expand', (e) => {
      this.classList.toggle('visible', e.detail === 'true');
    });
    this.created = true;
  }
}

if (customElements && customElements.define) {
  customElements.define('amedia-menubar', AmediaMenubar);
}
