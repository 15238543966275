const tag = document.createElement.bind(document);

function random(min, max) {
  min *= 100;
  max *= 100;
  return (Math.random() * (max - min + 1) + min) / 100;
}

let rotation = (Math.PI / 2) * 3;
const minScale = 0.3;
const maxScale = 1;
const increment = 0.06;
const spikes = 8;
const step = Math.PI / spikes;
let commonStar;
// 18 is dividible by both 3 and 2, and we avoid floating point in
// createStarDrawing
const starSize = 18;

function createStarDrawing(color, ratio) {
  const canvas = document.createElement('canvas');
  canvas.width = starSize * ratio;
  canvas.height = starSize * ratio;

  const ctx = canvas.getContext('2d');
  ctx.scale(ratio, ratio);

  const outerRadius = starSize / 2;
  const innerRadius = starSize / (starSize / 2);
  const mediumRadius = starSize / 3 - 1;

  const cx = starSize / 2;
  const cy = starSize / 2;

  let x = cx;
  let y = cy;

  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(cx, cy - outerRadius);

  let count = spikes;

  while (count > 0) {
    x = cx + Math.cos(rotation) * outerRadius;
    y = cy + Math.sin(rotation) * outerRadius;
    ctx.lineTo(x, y);
    rotation += step;

    x = cx + Math.cos(rotation) * innerRadius;
    y = cy + Math.sin(rotation) * innerRadius;
    ctx.lineTo(x, y);
    rotation += step;

    x = cx + Math.cos(rotation) * mediumRadius;
    y = cy + Math.sin(rotation) * mediumRadius;
    ctx.lineTo(x, y);
    rotation += step;

    x = cx + Math.cos(rotation) * innerRadius;
    y = cy + Math.sin(rotation) * innerRadius;
    ctx.lineTo(x, y);
    rotation += step;

    count -= 1;
  }

  ctx.lineTo(cx, cy - outerRadius);
  ctx.fill();
  ctx.closePath();
  return canvas;
}

function createStar(context, color, ratio, position) {
  if (commonStar === undefined) {
    commonStar = createStarDrawing(color, ratio);
  }

  return {
    x: position.x,
    y: position.y,
    ctx: context,
    color,
    pulseDir: 1,
    starScale: random(minScale, maxScale),
    ratio,

    // this.draw(this.starScale);
  };
}

function drawStar(star, scale) {
  const { ctx } = star;
  ctx.globalAlpha = scale;
  ctx.setTransform(star.ratio, 0, 0, star.ratio, 0, 0);
  ctx.translate(star.x, star.y);

  const size = starSize * scale;
  ctx.drawImage(commonStar, -(size / 2), -(size / 2), size, size);
  ctx.globalAlpha = 1;
}

function pulsateStar(star) {
  if (star.pulseDir === 1) {
    star.starScale -= increment;
    if (star.starScale < minScale) {
      star.pulseDir = -1;
    }
  } else {
    star.starScale += increment;
    if (star.starScale > maxScale) {
      star.pulseDir = 1;
    }
  }
  return star.starScale;
}

function reDrawStar(star) {
  drawStar(star, pulsateStar(star));
}

const limit = 25;
const canvas = tag('canvas');
const ctx = canvas.getContext('2d');
const stars = [];
let color = '#fff';
let frame = 0;

const startOfAnimation = Date.now();
const isReduced = window.matchMedia('(prefers-reduced-motion: reduce)');
function animate() {
  if (isReduced.matches) {
    return;
  }

  if (Date.now() - startOfAnimation > 5000) {
    return;
  }

  requestAnimationFrame(animate);

  // Only draw every 4th frame, to increase performance.
  frame = (frame + 1) % 4;
  if (frame !== 0) {
    return;
  }

  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  let i = limit;
  while (i > 0) {
    i -= 1;
    reDrawStar(stars[i]);
  }
}

class AmediaXmas extends HTMLElement {
  init() {
    // Is it Christmas?
    if (new Date().getMonth() !== 11) {
      return;
    }

    const width = this.offsetWidth;
    const height = this.offsetHeight;

    // use devicePixelRatio for hidpi devices
    const ratio = window.devicePixelRatio || 1;

    color = this.getAttribute('star-color');

    // increase canvas size for hidpi devices
    canvas.width = width * ratio;
    canvas.height = height * ratio;

    // scale canvas to original size with css
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    // increase context size for hidpi devices
    ctx.scale(ratio, ratio);

    this.appendChild(canvas);

    let i = limit;

    while (i > 0) {
      i -= 1;
      stars.push(
        createStar(ctx, color, ratio, {
          x: random(10, width - 10),
          y: random(10, height - 10),
        })
      );
    }
    window.addEventListener('load', () => animate());
  }

  connectedCallback() {
    if (!this.created) {
      this.init();
    }
    this.created = true;
  }
}

if (customElements && customElements.define) {
  customElements.define('foxfield-xmas', AmediaXmas);
}
